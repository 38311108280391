import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './style.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faBars, faPersonBiking, faScrewdriverWrench, faRepeat, faWallet, faXmark } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faBars)
library.add(faPersonBiking)
library.add(faScrewdriverWrench)
library.add(faRepeat)
library.add(faWallet)
library.add(faXmark)

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
  .use(vuetify)
  .mount('#app')
