<template>
  <v-card class="rounded-xl">
    <v-img src="@/assets/bicikl1.png" height="200"></v-img>
    <v-card-title class="align-center justify-center font-weight-bold">{{title}}</v-card-title>
    <v-card-text>{{subtitle}}</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "BikeCard",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    }
  }
}

</script>



<style scoped>

</style>