<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app temporary location="right">
      <!-- Navigation Drawer content here -->
      <v-list dense>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">About</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Add more items here -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar fixed color="#F28C28" elevation="1">
      <v-toolbar-title align="left" class="mr-5 mt-1">
        <v-img src="@/assets/logo.png" width="100"/>
      </v-toolbar-title>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <font-awesome-icon size="xl" :icon="['fas', 'bars']" />
      </v-app-bar-nav-icon>
    </v-app-bar>

    <v-main>
      <v-container class="pt-0 px-3 text-white" style="max-width: 100%">
        <v-img
            src="@/assets/banner.png"
            cover
            gradient="to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.5)"
            height="300"
            class="white--text"
            style="background-position:top !important;"
        >
          <div class="ml-4 mx-lg-16">
            <v-row align-self="center" style="height: 200px" class="mt-14" no-gutters>
              <v-col cols="12" align="center">
                <div style="width: 90%" class="headline text-white text-h5 font-weight-bold">Dobro došli u budućnost prijevoza u Zagrebu</div>
              </v-col>
              <v-col cols="12" align="center">
                <span class="headline text-white text-subtitle-1 font-weight-bold">Vaša biciklistička pretplata koja uključuje besplatne popravke i održavanje</span>
              </v-col>
            </v-row>
          </div>
        </v-img>

        <v-row class="my-3 py-5 px-4 text-black font-weight-bold">
          <v-col cols="6" sm="3" md="3" lg="3"  align="center">
            <v-row no-gutters class="flex-column">
              <v-col>
                <font-awesome-icon style="color: #F28C28" size="2x" :icon="['fas', 'person-biking']" />
              </v-col>
              <v-col  class="mt-2">
                Tvoj bicikl
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" sm="3" md="3" lg="3"  align="center">
            <v-row no-gutters class="flex-column">
              <v-col>
                <font-awesome-icon style="color: #F28C28" size="2x" :icon="['fas', 'screwdriver-wrench']" />
              </v-col>
              <v-col class="mt-2">
                Popravak uključen
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" sm="3" md="3" lg="3"  align="center">
            <v-row no-gutters class="flex-column">
              <v-col>
                <font-awesome-icon style="color: #F28C28" size="2x" :icon="['fas', 'wallet']" />
              </v-col>
              <v-col class="mt-2">
                Pristupačne cijene
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" sm="3" md="3" lg="3"  align="center">
            <v-row no-gutters class="flex-column">
              <v-col>
                <font-awesome-icon style="color: #F28C28" size="2x" :icon="['fas', 'xmark']" />
              </v-col>
              <v-col class="mt-2">
                Lako otkazivanje
              </v-col>
            </v-row>
          </v-col>
        </v-row>


        <v-row no-gutters>
          <v-col cols="12">
            <bike-card class="ma-4" title="Kvalitetni bicikli" subtitle="Ručna izrada lokalnih malih poduzeća" image="@/assets/bicikl1.png"></bike-card>
          </v-col>
        </v-row>

        <v-row class="text-black">
          <v-col cols="12" class="text-h6 font-weight-bold px-7">
            Cijene
          </v-col>
        </v-row>
        <v-row class="px-6 text-black font-weight-bold flex-column">
          <v-col cols="12"  align="center">
            <v-row class="elevation-1 pa-3 rounded" style="background-color:#FFD580" no-gutters align-self="center">
              <v-col class="text-h5">
                12 mj
              </v-col>
              <v-col class="text-h5">
                14,99 €
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12"  align="center">
            <v-row class="elevation-1 pa-2 rounded" style="background-color:#FFD580" no-gutters align-self="center">
              <v-col class="text-h5">
                9 mj
              </v-col>
              <v-col class="text-h5">
                16,99 €
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12"  align="center">
            <v-row class="elevation-1 pa-2 rounded" style="background-color:#FFD580" no-gutters align-self="center">
              <v-col class="text-h5">
                6 mj
              </v-col>
              <v-col class="text-h5">
                18,99 €
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12"  align="center">
            <v-row class="elevation-1 pa-2 rounded" style="background-color:#FFD580" no-gutters align-self="center">
              <v-col class="text-h5">
                3 mj
              </v-col>
              <v-col class="text-h5">
                19,99 €
              </v-col>
            </v-row>
          </v-col>
        </v-row>


        <v-row class="text-black mt-6">
          <v-col cols="12" class="text-h6 font-weight-bold px-7">
            Rezerviraj testnu vožnju
          </v-col>
          <v-col align="center">
            <v-form @submit.prevent="sendEmail" ref="form" id="contact-form" class="mx-4">
              <v-text-field
                  v-model="formData.from_name"
                  name="from_name"
                  label="Ime"
                  required
                  density="compact"
              ></v-text-field>

              <v-text-field
                  v-model="formData.contact"
                  name="contact"
                  label="E-mail"
                  type="email"
                  density="compact"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="formData.time"
                  name="time"
                  label="Vrijeme"
                  type="time"
                  density="compact"
                  required
              ></v-text-field>

              <v-textarea
                  v-model="formData.message"
                  name="message"
                  label="Poruka"
                  density="compact"
                  required
              ></v-textarea>
              <v-btn type="submit" color="primary" rounded >Pošalji</v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import BikeCard from "@/components/BikeCard.vue";
import * as emailjs from "emailjs-com";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'App',
  components: {FontAwesomeIcon, BikeCard},

  data: () => ({
    //
    drawer: false,
    formData: {
      from_name: '',
      contact: '',
      message: ''
    }
  }),

  methods: {
    sendEmail() {
      emailjs.sendForm(
          'service_mvb28m9',
          'template_hgy207c',
          '#contact-form',
          'IUXDtvfAnEjTsfdPC'
      ).then(
          () => {
            this.$refs.form.reset();
          }
      );
    }
  }
}
</script>

<style scoped>
body {
  font-family: 'Poppins', 'serif'
}
</style>
